import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSmsDetails } from '../../service/ApiService';
import Message from '../../componants/message/Message';
import { SmsDetails } from '../../models/Message';
import { LoadingDots } from '../../componants/loading/Loadings';
import { getFlag } from '../../service/ResourceService';
import { ErrorPage } from '../error/ErrorPage';
import { Tooltip } from '../../componants/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import refreshicon from '../../assets/refresh_icon.png'
import { Banner } from '../../componants/banner/Banner';

const SmsDetailPage = () => {
  const { t } = useTranslation()

  const { smsNumberId } = useParams();
  const [smsDetail, setSmsDetail] = useState<SmsDetails | null>(null);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [copyMsg, setCopyMsg] = useState(t("sms-detail-page.click-to-copy"));

  const [countryCode, number] = smsNumberId!.split("-");


  const hints: string[] = [
    t("sms-detail-page.hint-1"),
    t("sms-detail-page.hint-2")
  ]

  useEffect(() => {
    ReactGA.send({ hitType: `+${number}`, page: `/sms/${number}`, title: "Sms detail" });
  }, [])

  const fetchSmssDetail = async () => {
    getSmsDetails(number)
      .then((data) => {
        setSmsDetail(data);
      })
      .catch((err: Error) => {
        console.log("Error", err);
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!smsNumberId) {
      return;
    }
    fetchSmssDetail();
  }, []);

  const clickToCopy = () => {
    navigator.clipboard.writeText(number)
      .then(() => setCopyMsg(t("sms-detail-page.copied")));
  }

  return (
    <>
      <Helmet>
        <title>{`+${number}`}</title>
        <meta name="description" content={t('app.description')} />
      </Helmet>
      {
        error ? <ErrorPage message={t(error)} /> :
          <>
            <div className='flex flex-col content-center text-gray-800 m-auto relative shadow-lg shadow-gray-300' >
              <Banner>
                <div className='my-10'>
                  {
                    countryCode && <div className="w-[10rem] min-h-[6rem] relative mx-auto" >
                      <img className="w-full bot-0" src={getFlag(countryCode!)} alt={`${t('app.flag-img-alt')}: ${countryCode!}`} />
                    </div>
                  }
                  <div style={{ fontFamily: "Roboto,sans-serifn" }} className='text-center flex justify-center relative mx-auto mt-2'>
                    <Tooltip message={copyMsg}>
                      <p onClick={clickToCopy} className='text-white text-[2.2rem] hover:cursor-pointer hover:underline font-normal'>{`+${number}`}</p>
                    </Tooltip>
                  </div>
                  <div className='text-center mt-5'>
                    <h3 className='text-white font-sans text-[1.6rem]'>{t('sms-detail-page.receive-sms', { country: t(`countyName.${countryCode}`) })} </h3>
                  </div>
                </div>
              </Banner>
              <div className='bg-white p-6'>
                <div style={{ fontFamily: 'Open Sans", sans-serif' }} className='text-left max-w-[36rem] md:text-[1.1rem] relative mx-auto mt-1'>
                  <ul className='list-disc'>
                    {
                      hints.map((hint, key) => (
                        <li key={key} className='mt-3'>
                          {hint}
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className='flex justify-center mt-6'>
                  <button type="button" onClick={async () => {
                    setLoading(true);
                    fetchSmssDetail();
                  }} disabled={loading}
                    className="disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:cursor-wait
                      focus:outline-none text-white bg-green-700 
                      hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg 
                      text-lg pl-10 px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 
                      dark:focus:ring-green-800 h-14 w-[16rem] relative"><img src={refreshicon} className='absolute left-9 top-[1rem] h-2/5' alt="refresh icon" /> {t("sms-detail-page.update-messages")} </button>
                </div>
              </div>
            </div>
            <div className='mt-4 bg-white flex content-center flex-wrap md:w-[90%] lg:w-[70%] min-h-[70vh] h-auto m-auto relative bg-gray-100 pt-8 '>
              {
                loading ? <div className='w-full flex content-center justify-center'> <LoadingDots /> </div> :
                  <div className=' bg-white p-0 md:p-4 min-h-[100%]'>
                    {
                      smsDetail?.messages?.map((msg, key) => <Message key={key} message={msg.message} time={msg?.time} timeUnit={msg?.timeUnit} sender={msg.sender} />)
                    }
                  </div>
              }
            </div>
          </>
      }
    </>
  );
};

export default SmsDetailPage;