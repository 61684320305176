import { useTranslation } from "react-i18next";
import { TimeUnit } from "../../models/Message";


const Message = (props: {
    message?: string;
    sender?: string;
    timeUnit?: TimeUnit;
    time?: number;

}) => {
    const { t } = useTranslation()

    const getTimeUnit = (time?: number, timeUnit?: TimeUnit) => {
        if (!time) {
            return t("sms-detail-page.sms.not-determined")
        }
        switch (timeUnit) {
            case TimeUnit.NOW:
                return t("sms-detail-page.sms.now");
            case TimeUnit.SECOND:
                return time > 1 ? t("sms-detail-page.sms.seconds") : t("sms-detail-page.sms.second");
            case TimeUnit.MINUTE:
                return time > 1 ? t("sms-detail-page.sms.minutes") : t("sms-detail-page.sms.minute");
            case TimeUnit.HOUR:
                return time > 1 ? t("sms-detail-page.sms.hours") : t("sms-detail-page.sms.hour");
            case TimeUnit.DAY:
                return time > 1 ? t("sms-detail-page.sms.days") : t("sms-detail-page.sms.day");
            default:
                return t("sms-detail-page.sms.not-determined")
        }
    }

    const createMmsMarkup = (message: string) => {
        return {
            __html: message
        };
    }

    return (
        <div className="flex justify-start min-h-12 max-w-[80rem] bg-white border border-[#dadada] rounded ml-2 md:ml-10 mb-4">
            <div className="md:flex">
                <div className="flex relative min-w-[6rem] w-[8rem] md:w-[12rem] pb-5 pt-7 justify-center bg-gray-200">
                    <label className="text-nowrap absolute top-0 left-1 md:left-3 text-xs text-[#8a8a8a]">
                        {t("sms-detail-page.sms.sender")}:
                    </label>
                    <div className="my-auto font-semibold text-[#499c2d]">
                        {props.sender}
                    </div>
                </div>
                <div className="flex relative min-w-[6rem] w-[8rem] p-6 justify-center bg-gray-100">
                    <div className="my-auto text-nowrap text-sm md:text-base">
                        <p> {props.time} {getTimeUnit(props.time, props.timeUnit)} </p>
                    </div>
                </div>
            </div>
            <div className="flex-initial flex relative pl-1 pb-1 pt-1 md:pl-5 md:pb-6 ">
                <label className="absolute top-0 left-1 md:left-3 text-xs text-[#8a8a8a] text-nowrap">
                    {t("sms-detail-page.sms.message")}:
                </label>
                <div className="my-auto break-all" dangerouslySetInnerHTML={createMmsMarkup(props.message!)} />
            </div>
        </div>
    )
}

export default Message;