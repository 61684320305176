import { useEffect, useState } from 'react';
import { getSmss } from '../../service/ApiService';
import Card from '../../componants/card/Cards';
import { NumberBoxesItem } from '../../models/Message';
import { LoadingDots } from '../../componants/loading/Loadings';
import { useGlobalContext } from '../../GlobalContext';
import { ErrorPage } from '../error/ErrorPage';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Banner } from '../../componants/banner/Banner';

const HomePage = () => {
  const { t } = useTranslation();
  const { globalVariable, setGlobalVariable } = useGlobalContext()
  const [smsDetails, setSmsDetails] = useState<NumberBoxesItem[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });
  }, [])

  useEffect(() => {
    if (globalVariable.smsDetails) {
      setLoading(false);
      setSmsDetails(globalVariable.smsDetails);
    } else {
      getSmss().then((data) => {
        setSmsDetails(data);
        setGlobalVariable({
          ...globalVariable,
          smsDetails: data
        })
      }).catch((err: Error) => {
        setError(err.message);
      }).finally(() => setLoading(false));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('app.title')}</title>
        <meta name="description" content={t('app.description')} />
      </Helmet>
      {
        error ? <ErrorPage message={t(error)} /> :
          <>
            <Banner>
              <div className='my-5'>
                <div className='text-2xl md:text-6xl my-5 md:my-8 relative text-center '>
                  <h1>{t("home-page.title")}</h1>
                </div>
                <div className='text-xl md:text-3xl my-5 md:my-8 alegreya-sc-regular relative text-center'>
                  <h2>{t("home-page.second-title")}</h2>
                </div>
                <div className='text-md md:text-lg my-5 md:my-8 text-center relative'>
                  <p><span>Smss-otp.com </span> {t("home-page.description-1")}
                    <br /> {t("home-page.description-2")}</p>
                </div>
              </div>
            </Banner>
            <div className='flex content-center flex-wrap md:w-[90%] lg:w-[70%] min-h-[70vh] h-auto m-auto relative pt-8 pb-16'>

              {
                loading ? <div className='w-full flex content-center justify-center'> <LoadingDots /> </div> :
                  smsDetails?.sort((o1, o2) => (o1.countryCode >= o2.countryCode ? 1 : -1))
                    .map((sms: NumberBoxesItem, i) => (
                      <div key={i} className=" p-2 sm:p-4 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-0 sm:mb-4">
                        <Card
                          number={sms.number}
                          numberCode={sms.numberCode}
                          countryCode={sms.countryCode}
                          countryName={t(`countyName.${sms.countryCode}`)}
                        />
                      </div>
                    ))
              }
            </div>
          </>
      }
    </>
  );
};

export default HomePage;