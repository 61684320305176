import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/bubble-sms_icon-icons.com_54360.png'
import { useTranslation } from 'react-i18next';

function Header() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { lng } = useParams<{ lng: string }>();
    return (
        <header className='text-left bg-green-600 py-2.5 px-2 md:px-16'>
            <div className='flex hover:cursor-pointer hover:underline text-white'
                onClick={() => navigate(lng ? `./${lng}` : './')} >
                <div>
                    <img className='h-14' src={logo} alt="Logo" />
                </div>
                <div className='text-2xl md:text-3xl font-bold text-white alegreya-sc-regular-italic ml-3 my-auto '>
                    {t("app.receive-free-messages")}
                </div>
            </div>
        </header>
    );
}
export default Header;