export interface NumberBoxesItem {
  number: string;
  numberCode: string;
  countryCode: string;
  countryName: string;
}

export interface SmsDetails {
  countryCode: string | undefined;
  number: string | undefined;
  messages: Message[];
}

export interface Message {
  sender: string;
  time: number;
  timeUnit?: TimeUnit;
  message: string;
}

export enum TimeUnit {
  NOW = "now",
  SECOND = "second",
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  NOCOUNTED = "noCounted",
}
