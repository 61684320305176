import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import SmsDetailPage from './pages/sms-detail/SmsDetailPage';
import HomePage from './pages/home/HomePage';
import Header from './pages/Header';
import Footer from './pages/Footer';
import { GlobalProvider } from './GlobalContext';
import { NotFoundPage } from './pages/error/ErrorPage';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import { LanguageSwitcher } from './configuration/LanguageSwitcher';

function App() {

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID!);
  }, [])

  return (
    <>
      <Helmet>
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/en/`} hrefLang="en" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/hi/`} hrefLang="hi" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/id/`} hrefLang="id" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/th/`} hrefLang="th" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/vi/`} hrefLang="vi" />
        <link rel="alternate" href={`${process.env.REACT_APP_SERVER_DOMAIN}/zh/`} hrefLang="zh" />
      </Helmet>
      <BrowserRouter>
        <GlobalProvider>
          <Routes>
            <Route path="/" element={<Layout />} >
              <Route path="/:lng" element={<LanguageSwitcher />} >
                <Route path="/:lng/" element={<HomePage />} />
                <Route path="/:lng/sms/:smsNumberId" element={<SmsDetailPage />} />
                <Route path="/:lng/*" element={<NotFoundPage />} />
              </Route>
              <Route path="/" element={<HomePage />} />
              <Route path="/sms/:smsNumberId" element={<SmsDetailPage />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </GlobalProvider>
      </BrowserRouter>
    </>
  );
}

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <div className='pb-[16rem]'>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};


export default App;
